<template>
    <!-- preserveAspectRatio="xMinYMin slice" -->
    <!-- width="1014" height="165" -->
    <svg class="atom-confetti" viewBox="0 0 1014 165" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
        <ellipse fill="#FCC240" cx="294.452" cy="128.355" rx="5.983" ry="6"/>
        <ellipse fill="#7DC6B4" cx="603.063" cy="43.355" rx="5.983" ry="6"/>
        <ellipse fill="#7DC6B4" cx="300.063" cy="69.355" rx="5.983" ry="6"/>
        <ellipse fill="#7DC6B4" cx="619.016" cy="134.355" rx="5.983" ry="6"/>
        <ellipse fill="#7DC6B4" cx="100.016" cy="131.355" rx="5.983" ry="6"/>
        <ellipse fill="#EB5B50" cx="476.016" cy="83.355" rx="5.983" ry="6"/>
        <path fill="#7DC6B4" d="M382.705 148.355l-4.985 10 13.96-3z"/>
        <path fill="#FCC240" d="M507.714 14.635l11.314-3.907 3.895 11.347-11.313 3.906zM90.714 65.635l11.314-3.907 3.895 11.347L94.61 76.98zM769.714 65.635l11.314-3.907 3.895 11.347-11.313 3.906zM163.714 100.635l11.314-3.907 3.895 11.347-11.313 3.906zM982.714 125.635l11.314-3.907 3.895 11.347-11.313 3.906zM.714 98.635l11.314-3.907 3.895 11.347L4.61 109.98z"/>
        <path fill="#EB5B50" d="M352.168 87.635l10.596-1.28 4.613 8.72-10.596.28zM417.423 23.758l-4.488-9.711 6.843-7.095 3.54 10.02zM128.423 37.758l-4.488-9.711 6.843-7.095 3.54 10.02zM807.423 37.758l-4.488-9.711 6.843-7.095 3.54 10.02z"/>

        <path fill="#7DC6B4" d="M697.682 20.914l17.913-6.186 6.817 19.856-17.913 6.186z"/>

        <path fill="#7DC6B4" d="M8.682 31.914l17.913-6.186 6.817 19.856-17.913 6.186z"/>
        <path fill="#7DC6B4" d="M870.397 82.307l9.49 16.404-18.19 10.48-9.49-16.403z"/>
        <path fill="#EB5B50" d="M237.682 82.914l17.913-6.186 6.817 19.856-17.913 6.186z"/>
        <path fill="#7DC6B4" d="M477.353 29.405l5.78-9.206-12.286 1.085zM222.353 74.405l5.78-9.206-12.286 1.085z"/>
        <path fill="#7DC6B4" d="M747.283 132.858l-6.402 9.572 16.15-1.897z"/>
        <path fill="#EB5B50" d="M44.19 157.285l-2.24-11.296-10.078 12.76z"/>
        <path fill="#FCC240" d="M960.19 38.285l-2.24-11.296-10.078 12.76z"/>
        <path d="M538.165 80.424c5.154-1.47 8.566-3.646 10.234-6.53l3.923 1.59c-2.113 5.245-6.183 8.426-12.21 9.543l-1.947-4.603z" fill="#EB5B50"/>
        <path d="M403.525 56.711c-4.846 5.299-6.938 10.317-6.276 15.054l-6.198.632c-2.316-8-.363-14.72 5.861-20.16l6.613 4.474zM191.525 30.711c-4.846 5.299-6.938 10.317-6.276 15.054l-6.198.632c-2.316-8-.363-14.72 5.861-20.16l6.613 4.474z" fill="#7DC6B4"/>
        <path d="M916.653 117.654c7.103 1.055 12.433-.017 15.99-3.215l3.99 4.785c-5.337 6.395-12 8.533-19.99 6.414l.01-7.984z" fill="#EB5B50"/>
        <path d="M341.602 34.793c.522-11.853-2.14-20.421-7.987-25.704l7.166-7.392c11.388 7.648 16.025 18.22 13.91 31.716l-13.089 1.38zM731.417 90.654c-10.429 5.657-16.402 12.352-17.918 20.085l-10.027-2.335c.69-13.701 7.364-23.12 20.02-28.259l7.925 10.51z" fill="#FCC240"/>
        <path fill="#7DC6B4" d="M901.353 74.405l5.78-9.206-12.286 1.085zM870.525 30.711c-4.846 5.299-6.938 10.317-6.276 15.054l-6.198.632c-2.316-8-.363-14.72 5.861-20.16l6.613 4.474z"/>
        <path fill="#EB5B50" d="M998.714 9.635l11.314-3.907 3.895 11.347-11.313 3.906z"/>
        <ellipse fill="#7DC6B4" cx="993.016" cy="76.355" rx="5.983" ry="6"/>
    </svg>
</template>



<style>
    .atom-confetti {
        display: block;
        width: 100%;

        height: 165px;
        max-width: 1014px;

        margin-left: auto;
        margin-right: auto;

        margin-bottom: -155px;

    }

    /* 460px + margin-x's */
    @media (min-width: 492px) {
        .atom-confetti {
            margin-bottom: -80px;
        }
    }
</style>
