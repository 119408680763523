@font-face {
    font-family: 'Lato';
    font-weight: 800;
    src: url('./assets/fonts/lato/LatoLatin-Heavy.woff2') format('woff2');
}

@font-face {
    font-family: 'Lato';
    font-weight: 700;
    src: url('./assets/fonts/lato/LatoLatin-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Lato';
    font-weight: 600;
    src: url('./assets/fonts/lato/LatoLatin-Semibold.woff2') format('woff2');
}

@font-face {
    font-family: 'Lato';
    font-weight: 400;
    src: url('./assets/fonts/lato/LatoLatin-Regular.woff2') format('woff2');
}


:root {
    font-family: 'Lato', sans;

    --white: #ffffff;
    --black: #000000;
    --light-grey: #ebebeb;
    --grey: #b8b8b8;
    --dark-grey: #7a7a7a;
    --green: #7dc6b4;
    --yellow: #fcc240;

    --red: #eb5b50;
    --red-hover: #d15147;
    --red-pressed: #b8473f;

    --super-light-grey: #f2f2f2;
    --super-light-grey-hover: #ebebeb;
    --super-light-grey-pressed: #e6e6e6;

    --disabled: #ebebeb;
}
